import React from "react";
import { useNavigate } from "react-router";
import { Header } from "../containers/Header";
import { MobileHeader } from "../containers/MobileHeader";
import useWindowSize from "../hooks/useWindowSize";

const ErrorPage: React.FC = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  return (
    <div style={{ marginBottom: "50vh" }}>
      {width > 1000 ? <Header /> : <MobileHeader />}
      <div>
        <h1>Greška pri plaćanju</h1>
        <p>
          Bilo je grešaka prilikom obrade vaše uplate. Molimo Vas pokušajte
          kasnije ili kontaktirajte podršku.
        </p>
        <button className="addInBasketButton" onClick={() => navigate("/")}>
          Nazad na početnu
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
