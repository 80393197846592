//react
import { FC } from "react";
//styles
import "../styles/components/search.css";
//icons
import { BiSearchAlt } from "react-icons/bi";

interface SearchProps {
  suggestionObj: {
    activeSuggestion: number;
    filteredSuggestions: string[];
    showSuggestions: boolean;
    userInput: string;
  };
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onClick: (value: string) => void;
  closeSuggestions: boolean;
  searchRef: React.MutableRefObject<null>;
  onSearchButtonClick: () => void;
  onCancelSearchButtonClick: () => void;
  enableSearch: boolean;
}

export const Search: FC<SearchProps> = ({
  suggestionObj,
  onChange,
  onKeyDown,
  onClick,
  closeSuggestions,
  searchRef,
  onSearchButtonClick,
  onCancelSearchButtonClick,
  enableSearch,
}): JSX.Element | null => {
  const renderSuggestions = (
    <ul className="suggestions">
      {suggestionObj.filteredSuggestions.map((suggestion, index) => {
        let className;
        if (index === suggestionObj.activeSuggestion) {
          className = "suggestion-active";
        }
        return (
          <li
            className={className}
            key={suggestion}
            onClick={() => onClick(suggestion)}
          >
            {suggestion}
          </li>
        );
      })}
    </ul>
  );

  return (
    <div className="searchComponent" ref={searchRef}>
      <div className="searchTop">
        <div className="leftTop">
          <BiSearchAlt size={34} className="searchIcon" />
          <input
            type="text"
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={suggestionObj.userInput}
            placeholder="Šta biste pročitali?"
            disabled={!enableSearch}
          />
        </div>
        {enableSearch ? (
          <button className="searchButton" onClick={onSearchButtonClick}>
            Pretraži
          </button>
        ) : (
          <button
            className="cancelSearchButton"
            onClick={onCancelSearchButtonClick}
          >
            Poništi pretragu
          </button>
        )}
      </div>
      {!closeSuggestions &&
        suggestionObj.userInput.length >= 1 &&
        suggestionObj.filteredSuggestions &&
        renderSuggestions}
    </div>
  );
};
