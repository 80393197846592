//icons
import { FaPhone } from "react-icons/fa6";
import { MdOutlineMailOutline } from "react-icons/md";
//hooks
import useWindowSize from "../hooks/useWindowSize";

export const Footer = () => {
  const { width } = useWindowSize();
  return (
    <div
      style={{
        height: "93px",
        width: "100%",
        backgroundColor: "#2b3540",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "80px",
        color: "white",
      }}
    >
      <div className="contact">
        <FaPhone />
        <p
          style={{
            marginLeft: "10px",
            fontSize: width > 500 ? "15px" : "12px",
            fontWeight: 500,
          }}
        >
          Pozovite nas na{"  "}
          <a
            href="tel:+381 60 3114400"
            style={{ textDecoration: "underline", color: "white" }}
          >
            +381 60 311 4400
          </a>
        </p>
      </div>
      <div className="contact">
        <MdOutlineMailOutline
          style={{ marginLeft: width < 500 ? "10px" : 0 }}
        />
        <p
          style={{
            marginLeft: "10px",
            fontSize: width > 500 ? "15px" : "12px",
            fontWeight: 500,
          }}
        >
          Pošaljite nam mejl na{"  "}
          <a
            href="https://mail.google.com/mail/?view=cm&fs=1&to=susnjicpublishing@gmail.com&su=SUBJECT&body=BODY"
            style={{ textDecoration: "underline", color: "white" }}
          >
            susnjicpublishing@gmail.com
          </a>
        </p>
      </div>
    </div>
  );
};
