//react
import { Helmet } from "react-helmet";
import { useEffect, useRef, useState } from "react";
//hooks
import { useParams } from "react-router";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import { useSetRecoilState } from "recoil";
//styles
import "../styles/pages/selectedBookPage.css";
//data
import { selectedBooks } from "../../data/selectedBook";
//interfaces
import { CartItem, SelectedBookType } from "../../domain/interfaces/book";
//icons
import { IoIosCloseCircleOutline } from "react-icons/io";
//atoms
import { cartAtom } from "../../domain/atoms/shopCart";
import { Header } from "../containers/Header";
import { MobileHeader } from "../containers/MobileHeader";
import useWindowSize from "../hooks/useWindowSize";

export const SelectedBookPage = () => {
  const { id } = useParams();
  const [selectedBook, setSelectedBook] = useState<
    SelectedBookType | undefined
  >(undefined);
  const modalRef = useRef(null);
  const modalID = document.getElementById("modalID");
  const setCart = useSetRecoilState(cartAtom);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const { width } = useWindowSize();

  function getCartItems(): CartItem[] {
    const cartString = localStorage.getItem("books");
    return cartString ? JSON.parse(cartString) : [];
  }

  // Function to add an item to the cart
  function addToCart(item: CartItem) {
    // Display popup
    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 2000);
    // Get the current cart items from local storage
    let cart = getCartItems();

    // Push the new item to the cart
    cart.push(item);

    // Update the cart items in local storage
    localStorage.setItem("books", JSON.stringify(cart));
    // Update cart atom
    setCart(cart);
  }

  useEffect(() => {
    if (id) {
      let findSelectedBook = selectedBooks.filter((book) => book.id === +id);
      setSelectedBook(findSelectedBook[0]);
    }
  }, [id]);

  useEffect(() => {
    if (selectedBook) {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("showModal");
            observer.unobserve(entry.target);
          }
        });
      }, options);

      if (modalRef.current) {
        observer.observe(modalRef.current);
      }

      return () => {
        if (modalRef.current) {
          /* eslint-disable */
          observer.unobserve(modalRef.current);
        }
      };
    }
    /* eslint-disable */
  }, [modalRef, selectedBook]);

  useOnClickOutside(modalRef, () => modalID?.classList.remove("showModal"));

  const addInCartAndCloseModal = () => {
    addToCart({ id: Number(selectedBook?.id) });
    modalID?.classList.remove("showModal");
  };

  return (
    <>
      <Helmet>
        <title>Izabrana knjiga - Šušnjic publishing</title>
        <meta
          name="description"
          content="Otkrijte svet misli i dela Đure Šušnjića. Nudimo ekskluzivni izbor njegovih najvažnijih dela koja obuhvataju širok spektar socioloških i filozofskih tema. Idealno mesto za sve one željne dubokog razumevanja društvenih fenomena."
        />
        <link rel="canonical" href="https://www.djurosusnjic.com/" />
      </Helmet>
      {width > 1000 ? <Header /> : <MobileHeader />}
      {/* popup */}
      <div className={`popup ${showPopup ? "show" : ""}`}>
        <div className="popup-content">Knjiga je uspešno dodata!</div>
      </div>
      {/* popup */}
      <div className="selectedBookContent">
        <div className="selectedBookTopContent">
          <div className="leftSelectedBook">
            <img
              src={`${selectedBook?.image}`}
              alt="slika izabrane knjige"
              className="selectedBookImage"
            />
            <div className="selectedBookBasicContent">
              <div className="leftTopSelectedBook">
                <p className="selectedBookAuthor">{selectedBook?.author}</p>
                <p className="selectedBookName">{selectedBook?.name}</p>
              </div>
              <div className="leftBottomSelectedBook">
                <p className="selectedBookPriceTitle">Cena fizičke kopije</p>
                <p className="selectedBookPrice">RSD{selectedBook?.price}</p>
                <div>
                  <p className="selectedBookPriceTitle">Cena e-book</p>
                  <p className="selectedBookPrice">
                    RSD{selectedBook?.ebookPrice}
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <button
                    className="addInBasketButton"
                    onClick={() => addToCart({ id: Number(selectedBook?.id) })}
                  >
                    Dodaj u korpu
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="rightSelectedBook">
            <p>{selectedBook?.description}</p>
            <p>{selectedBook?.descriptionTwo}</p>
            <p>{selectedBook?.descriptionThree}</p>
            <p>{selectedBook?.descriptionFour}</p>
          </div>
        </div>
        <div className="selectedBookBottomContent">
          <p style={{ display: "flex", justifyContent: "center" }}>
            {selectedBook?.pageTitle}
          </p>
          <p style={{ display: "flex", justifyContent: "center" }}>
            {selectedBook?.pageSubtitle}
          </p>
          <p>{selectedBook?.pageOne}</p>
          <p>{selectedBook?.pageTwo}</p>
        </div>
      </div>
      <div className="modalContent" ref={modalRef} id="modalID">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "80%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p>
            Uživajte u čitanju cele knjige tako sto ćete je kupiti u fizičkom
            ili elektronskom obliku
          </p>
          <IoIosCloseCircleOutline
            size={"28px"}
            onClick={() => modalID?.classList.remove("showModal")}
            className="closeModalIcon"
          />
        </div>
        <button className="modalAddButton" onClick={addInCartAndCloseModal}>
          Dodaj u korpu
        </button>
      </div>
    </>
  );
};
