//react
import { useEffect, useState } from "react";
//styles
import "../styles/containers/mobileHeader.css";
//icons
import { SlBasket } from "react-icons/sl";
import { FaPhone } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";
//images
import Logo from "../../assets/images/sukaLogoCopy.jpg";
//interfaces
import { CartItem } from "../../domain/interfaces/book";
//hooks
import { useRecoilValue } from "recoil";
//atoms
import { cartAtom } from "../../domain/atoms/shopCart";
import { useNavigate } from "react-router-dom";

export const MobileHeader = () => {
  const [cartBooks, setCartBooks] = useState<CartItem[]>([]);
  const cart = useRecoilValue(cartAtom);
  const navigate = useNavigate();

  function openNav() {
    let mobileLogo = document.querySelector("#mobileLogo");
    let closeElementByID = document.getElementById("mobileCloseDiv");
    let elementByID = document.getElementById("sidebar");
    let mobileNav = document.getElementById("mobileNav");
    elementByID && elementByID.classList.add("open");
    if (mobileLogo) {
      mobileLogo.classList.remove("visible");
      mobileLogo.classList.add("hidden");
    }
    if (closeElementByID) {
      closeElementByID.style.display = "flex";
    }
    if (mobileNav) {
      mobileNav.classList.remove("hidden");
      mobileNav.classList.add("visible");
    }
  }

  function closeNav() {
    let mobileLogo = document.querySelector("#mobileLogo");
    let closeElementByID = document.getElementById("mobileCloseDiv");
    let elementByID = document.getElementById("sidebar");
    let mobileNav = document.getElementById("mobileNav");
    elementByID && elementByID.classList.remove("open");
    if (mobileLogo) {
      mobileLogo.classList.remove("hidden");
      mobileLogo.classList.add("visible");
    }
    if (closeElementByID) {
      closeElementByID.style.display = "none";
    }
    if (mobileNav) {
      mobileNav.classList.remove("visible");
      mobileNav.classList.add("hidden");
    }
  }

  useEffect(() => {
    let booksInCart = localStorage.getItem("books");

    booksInCart && setCartBooks(JSON.parse(booksInCart));
  }, [cart]);

  return (
    <div className="mobileHeader">
      <div className="closeDiv" onClick={closeNav} id="mobileCloseDiv">
        <IoMdClose size={"22px"} />
      </div>
      <RxHamburgerMenu
        size={"26px"}
        style={{ cursor: "pointer" }}
        onClick={openNav}
      />
      <img src={Logo} alt="logo" className="mobileLogo" id="mobileLogo" />
      <div className="rightDiv">
        <a href="tel:+381 60 3114400" className="backgroundPhoneMobile">
          <FaPhone />
        </a>
        <div
          className="backgroundBasket"
          onClick={() => navigate("/cart-review")}
        >
          <SlBasket />
          {cartBooks.length > 0 && (
            <p style={{ fontSize: "12px", marginTop: 1, marginLeft: 1.5 }}>
              {cartBooks.length}
            </p>
          )}
        </div>
      </div>
      <div className="sidebar" id="sidebar">
        <div className="sidebarContent">
          <img src={Logo} alt="logo" className="menuMobileLogo" />
          <nav className="mobileNav" id="mobileNav">
            <a href="/">Početna</a>
            <a href="/biography">O Đuri Šušnjiću</a>
            <a href="/opinions">Drugi o nama</a>
            <a href="/gallery">Galerija</a>
          </nav>
        </div>
      </div>
    </div>
  );
};
