import React from "react";
import { useNavigate } from "react-router";
import useWindowSize from "../hooks/useWindowSize";
import { Header } from "../containers/Header";
import { MobileHeader } from "../containers/MobileHeader";

const SuccessPage: React.FC = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();

  return (
    <div style={{ marginBottom: "50vh" }}>
      {width > 1000 ? <Header /> : <MobileHeader />}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1>Uspešno plaćanje</h1>
        <p>Vaša uplata je uspešno izvršena. Hvala Vam na kupovini!</p>
        <p>
          Pristup e-booku ćete dobiti putem mejla, na koji se vodi Vaš PayPal
          nalog, u roku od sat vremena.
        </p>
        <button className="addInBasketButton" onClick={() => navigate("/")}>
          Nazad na početnu
        </button>
      </div>
    </div>
  );
};

export default SuccessPage;
