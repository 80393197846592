import { Helmet } from "react-helmet";
import { galleryArray } from "../../data/gallery";
import { Header } from "../containers/Header";
import { MobileHeader } from "../containers/MobileHeader";
import useWindowSize from "../hooks/useWindowSize";
import "../styles/pages/galleryPage.css";

export const GalleryPage = () => {
  const { width } = useWindowSize();
  let arrayOne = galleryArray.slice(0, 8);
  let arrayTwo = galleryArray.slice(8, galleryArray.length);

  const biggerImages = arrayOne.map((image, index) => (
    <div key={index} className="galleryImageDiv">
      <img src={image} alt="slika" className="galleryImage" />
    </div>
  ));

  const smallerImages = arrayTwo.map((image, index) => (
    <div key={index} className="galleryImageDivSmaller">
      <img src={image} alt="slika" className="galleryImage" />
    </div>
  ));

  return (
    <div
      style={{
        marginBottom: "15vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Helmet>
        <title>Galerija Đure Šušnjića</title>
        <meta
          name="description"
          content="Otkrijte svet misli i dela Đure Šušnjića. Nudimo ekskluzivni izbor njegovih najvažnijih dela koja obuhvataju širok spektar socioloških i filozofskih tema. Idealno mesto za sve one željne dubokog razumevanja društvenih fenomena."
        />
        <link rel="canonical" href="https://www.djurosusnjic.com/gallery" />
      </Helmet>
      {width > 1000 ? <Header /> : <MobileHeader />}
      <div className="galleryPageContent">{biggerImages}</div>
      <div className="galleryPageContentSmaller" style={{ marginTop: "20px" }}>
        {smallerImages}
      </div>
    </div>
  );
};
