//images for current books
import CurrentBookOne from "../assets/images/landingPage/bookOne/OglediOSkrivenojStraniStvariOp.png";
import CurrentBookTwo from "../assets/images/landingPage/bookTwo/ZetvaZnacenjaOp.png";
import CurrentBookThree from "../assets/images/landingPage/bookThree/DijalogITolerancijaOp.png";
import CurrentBookFour from "../assets/images/landingPage/bookFour/ZnatiIVerovatiOp.png";
import CurrentBookFive from "../assets/images/landingPage/bookFive/SjajOdlomkaOp.png";
import CurrentBookSix from "../assets/images/landingPage/bookSix/SecanjeIZaboravljanjeOp.png";
import CurrentBookSeven from "../assets/images/landingPage/bookSeven/RibariLjudskihDusaOp.png";
//images for coming soon books
import ComingSoonBookOne from "../assets/images/landingPage/comingSoon/bookOne/MetodologijaOp.png";
import ComingSoonBookTwo from "../assets/images/landingPage/comingSoon/bookTwo/TeorijeKultureOp.png";
import ComingSoonBookThree from "../assets/images/landingPage/comingSoon/bookThree/ReligijaOp.png";
import ComingSoonBookFour from "../assets/images/landingPage/comingSoon/bookFour/SakuraOp.png";
import ComingSoonBookFive from "../assets/images/landingPage/comingSoon/bookFive/MetodeIPismoOp.png";
import ComingSoonBookSix from "../assets/images/landingPage/comingSoon/bookSix/CvetoviITlaOp.png";
import ComingSoonBookSeven from "../assets/images/landingPage/comingSoon/bookSeven/OtporiKritickomMisljenjuOp.png";
import ComingSoonBookEight from "../assets/images/landingPage/comingSoon/bookEight/NedovrsenRazgovorOp.png";
import ComingSoonBookNine from "../assets/images/landingPage/comingSoon/bookNine/DramaRazumevanjaOp.png";

//current books array
export const currentBooks = [
  {
    id: 1,
    name: "Ogledi o skrivenoj strani stvari",
    price: "1.300",
    ebookPrice: "600",
    image: CurrentBookOne,
  },
  {
    id: 2,
    name: "Žetva značenja",
    price: "1.300",
    ebookPrice: "600",
    image: CurrentBookTwo,
  },
  {
    id: 3,
    name: "Dijalog i tolerancija",
    price: "1.600",
    ebookPrice: "600",
    image: CurrentBookThree,
  },
  {
    id: 4,
    name: "Znati i verovati",
    price: "1.800",
    ebookPrice: "600",
    image: CurrentBookFour,
  },
  {
    id: 5,
    name: "Sjaj odlomka",
    price: "1.800",
    ebookPrice: "600",
    image: CurrentBookFive,
  },
  {
    id: 6,
    name: "Sećanje i zaboravljanje",
    price: "1.700",
    ebookPrice: "600",
    image: CurrentBookSix,
  },
  {
    id: 7,
    name: "Ribari ljudskih duša",
    price: "2.000",
    ebookPrice: "600",
    image: CurrentBookSeven,
  },
];

//coming soon books array
export const comingSoonBooks = [
  {
    id: 8,
    name: "Metodologija",
    price: "",
    ebookPrice: "",
    image: ComingSoonBookOne,
  },
  {
    id: 9,
    name: "Teorije kulture",
    price: "",
    ebookPrice: "",
    image: ComingSoonBookTwo,
  },
  {
    id: 10,
    name: "Religija I i II",
    price: "",
    ebookPrice: "",
    image: ComingSoonBookThree,
  },
  {
    id: 11,
    name: "Sociopsihološke posledice vertikalne pokretljivosti",
    price: "",
    ebookPrice: "",
    image: ComingSoonBookFour,
  },
  {
    id: 12,
    name: "Metode i pismo",
    price: "",
    ebookPrice: "",
    image: ComingSoonBookFive,
  },
  {
    id: 13,
    name: "Cvetovi i tla",
    price: "",
    ebookPrice: "",
    image: ComingSoonBookSix,
  },
  {
    id: 14,
    name: "Otpori kritičkom mišljenju",
    price: "",
    ebookPrice: "",
    image: ComingSoonBookSeven,
  },
  {
    id: 15,
    name: "Nedovršen razgovor",
    price: "",
    ebookPrice: "",
    image: ComingSoonBookEight,
  },
  {
    id: 16,
    name: "Drama razumevanja",
    price: "",
    ebookPrice: "",
    image: ComingSoonBookNine,
  },
];

//all books titles array

export const allBooksTitles = [
  "Ogledi o skrivenoj strani stvari",
  "Žetva žnačenja",
  "Dijalog i tolerancija",
  "Znati i verovati",
  "Sjaj odlomka",
  "Sećanje i zaboravljanje",
  "Ribari ljudskih duša",
  "Metodologija",
  "Teorije kulture",
  "Sociopsihološke posledice vertikalne pokretljivosti",
  "Metode i pismo",
  "Cvetovi i tla",
  "Otpori kritičkom mišljenju",
  "Nedovršen razgovor",
  "Drama razumevanja",
];
