//react
import { useEffect, useState } from "react";
//styles
import "../styles/containers/header.css";
//icons
import { SlBasket } from "react-icons/sl";
import { FaPhone } from "react-icons/fa6";
//images
import Logo from "../../assets/images/sukaLogoCopy.jpg";
//interfaces
import { CartItem } from "../../domain/interfaces/book";
//hooks
import { useRecoilValue } from "recoil";
//atoms
import { cartAtom } from "../../domain/atoms/shopCart";
import { useNavigate } from "react-router-dom";

export const Header = () => {
  const [cartBooks, setCartBooks] = useState<CartItem[]>([]);
  const cart = useRecoilValue(cartAtom);
  const navigate = useNavigate();

  useEffect(() => {
    let booksInCart = localStorage.getItem("books");

    booksInCart && setCartBooks(JSON.parse(booksInCart));
  }, [cart]);

  return (
    <div className="header">
      <div className="top">
        <div className="contact">
          <div className="backgroundPhone">
            <FaPhone />
          </div>
          <p
            style={{
              marginLeft: "10px",
              fontSize: "15px",
              fontWeight: 500,
            }}
          >
            Pozovite nas na{"  "}
            <a
              href="tel:+381 60 3114400"
              style={{ textDecoration: "underline", color: "white" }}
            >
              +381 60 311 4400
            </a>
          </p>
        </div>
        <img src={Logo} alt="logo" className="logo" />
        <div
          className="backgroundBasket"
          onClick={() => navigate("/cart-review")}
        >
          <SlBasket />
          {cartBooks.length > 0 && (
            <p style={{ fontSize: "12px", marginTop: 1, marginLeft: 1.5 }}>
              {cartBooks.length}
            </p>
          )}
        </div>
      </div>
      <hr style={{ width: "99.5%", border: "0.5px solid #467abf" }} />
      <div className="bottom">
        <nav className="nav">
          <a href="/">Početna</a>
          <a href="/biography">O Đuri Šušnjiću</a>
          <a href="/opinions">Drugi o nama</a>
          <a href="/gallery">Galerija</a>
        </nav>
      </div>
    </div>
  );
};
