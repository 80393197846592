//react
import { Helmet } from "react-helmet";
import { useState } from "react";
//data
import { biography } from "../../data/biography";
//styles
import "../styles/pages/biographyPage.css";
//icons
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import useWindowSize from "../hooks/useWindowSize";
import { Header } from "../containers/Header";
import { MobileHeader } from "../containers/MobileHeader";

export const BiographyPage = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const { width } = useWindowSize();

  const biographyImageItems = biography.images.map((image, index) => (
    <img
      key={index}
      src={image}
      alt="slika Djure Šušnjića"
      className="biographySlide"
    />
  ));

  const goToPrevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? biography.images.length - 1 : prevIndex - 1
      );
    }
  };

  const goToNextSlide = () => {
    if (currentIndex < biography.images.length - 1) {
      setCurrentIndex((prevIndex) =>
        prevIndex === biography.images.length - 1 ? 0 : prevIndex + 1
      );
    }
  };
  return (
    <>
      <Helmet>
        <title>O Đuri Šušnjiću</title>
        <meta
          name="description"
          content="Otkrijte svet misli i dela Đure Šušnjića. Nudimo ekskluzivni izbor njegovih najvažnijih dela koja obuhvataju širok spektar socioloških i filozofskih tema. Idealno mesto za sve one željne dubokog razumevanja društvenih fenomena."
        />
        <link rel="canonical" href="https://www.djurosusnjic.com/biography" />
      </Helmet>
      {width > 1000 ? <Header /> : <MobileHeader />}
      <div className="biographyPage" style={{ marginBottom: "32vh" }}>
        <div className="biographyPageContent">
          <div className="biographyBasic">
            <div className="arrowDiw">
              <IoIosArrowBack onClick={goToPrevSlide} className="arrow prev" />
              <IoIosArrowForward
                onClick={goToNextSlide}
                className="arrow next"
              />
            </div>
            <div className="biographySlider">
              {biographyImageItems.slice(currentIndex, currentIndex + 1)}
            </div>
            <p className="biographyName">{biography.name}</p>
            {/* <div className="biographyDateDiv">
              <p>Datum rođenja: </p>
              <p>{biography.dateOfBirth}</p>
            </div>
            <div className="biographyPlaceDiv">
              <p>Mesto rođenja: </p>
              <a href="https://sr.wikipedia.org/wiki/%D0%A0%D1%83%D0%B4%D0%BE%D0%BF%D0%BE%D1%99%D0%B5">
                {biography.placeOfBirth}
              </a>
            </div> */}
          </div>
          <div className="biographyText">
            <p style={{ fontSize: "22px", marginTop: "0px" }}>
              {biography.title}
            </p>
            <p>{biography.parOne}</p>
            <p>{biography.partwo}</p>
            <p>{biography.parThree}</p>
            <p>{biography.parFour}</p>
            <p>{biography.parFive}</p>
            <p>{biography.parSix}</p>
            <p>{biography.parSeven}</p>
            <p>{biography.parEight}</p>
            <p>{biography.parNine}</p>
          </div>
        </div>
      </div>
    </>
  );
};
