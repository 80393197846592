//react
import { ChangeEvent, useEffect, useRef, useState } from "react";
//hooks
import { useOnClickOutside } from "./useOnClickOutside";
import { useNavigate } from "react-router";
import useWindowSize from "./useWindowSize";
//data
import { comingSoonBooks, currentBooks } from "../../data/books";

const useSearch = (suggestions: string[]) => {
  const [suggestionObj, setSuggestionObj] = useState<{
    activeSuggestion: number;
    filteredSuggestions: string[];
    showSuggestions: boolean;
    userInput: string;
  }>({
    activeSuggestion: 0,
    filteredSuggestions: [],
    showSuggestions: false,
    userInput: "",
  });
  const [closeSuggestions, setCloseSuggestions] = useState<boolean>(false);
  const searchRef = useRef(null);
  const [searchBooks, setSearchBooks] = useState<
    {
      id: number;
      name: string;
      price: string;
      image: any;
    }[]
  >([]);
  const [enableSearch, setEnableSearch] = useState<boolean>(true);
  const navigate = useNavigate();
  const { width } = useWindowSize();

  useOnClickOutside(searchRef, () => setCloseSuggestions(true));

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setCloseSuggestions(false);
    const value = e.target.value;
    const filteredSuggestions = suggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestionObj({
      activeSuggestion: 0,
      filteredSuggestions: filteredSuggestions,
      showSuggestions: true,
      userInput: value,
    });
  };

  const onClick = (value: string) => {
    setSuggestionObj({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: value,
    });
    let currentResults = currentBooks.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    if (currentResults.length === 1) {
      navigate(`/${currentResults[0].id}`);
    } else {
      alert("Izabrana knjiga još uvek nije dostupna.");
    }
  };

  const onSearchButtonClick = () => {
    if (suggestionObj.userInput.length > 1) {
      let currentResults = currentBooks.filter((item) =>
        item.name.toLowerCase().includes(suggestionObj.userInput.toLowerCase())
      );
      let comingResults = comingSoonBooks.filter((item) =>
        item.name.toLowerCase().includes(suggestionObj.userInput.toLowerCase())
      );
      setSearchBooks(currentResults.concat(comingResults));
      setEnableSearch(false);
      setSuggestionObj({
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
        userInput: suggestionObj.userInput,
      });
    } else {
      console.log(
        "Minimalan broj slova za pretragu je 2.(#740316 pozadina, bela slova)"
      );
    }
  };

  const onCancelSearchButtonClick = () => {
    setSuggestionObj({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: "",
    });
    setSearchBooks([]);
    setEnableSearch(true);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setSuggestionObj({
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
        userInput:
          suggestionObj.filteredSuggestions.length > 0
            ? suggestionObj.filteredSuggestions[suggestionObj.activeSuggestion]
            : suggestionObj.userInput,
      });
    } else if (e.key === "ArrowUp") {
      if (suggestionObj.activeSuggestion === 0) {
        return;
      }
      setSuggestionObj({
        activeSuggestion: suggestionObj.activeSuggestion - 1,
        filteredSuggestions: suggestionObj.filteredSuggestions,
        showSuggestions: suggestionObj.showSuggestions,
        userInput: suggestionObj.userInput,
      });
    } else if (e.key === "ArrowDown") {
      if (
        suggestionObj.activeSuggestion - 1 ===
        suggestionObj.filteredSuggestions.length
      ) {
        return;
      }
      setSuggestionObj({
        activeSuggestion: suggestionObj.activeSuggestion + 1,
        filteredSuggestions: suggestionObj.filteredSuggestions,
        showSuggestions: suggestionObj.showSuggestions,
        userInput: suggestionObj.userInput,
      });
    }
  };

  useEffect(() => {
    if (width < 501) {
      if (suggestionObj.userInput.length > 1) {
        let currentResults = currentBooks.filter((item) =>
          item.name
            .toLowerCase()
            .includes(suggestionObj.userInput.toLowerCase())
        );
        let comingResults = comingSoonBooks.filter((item) =>
          item.name
            .toLowerCase()
            .includes(suggestionObj.userInput.toLowerCase())
        );
        setSearchBooks(currentResults.concat(comingResults));
      } else {
        setSearchBooks([]);
      }
    }
  }, [suggestionObj, width]);

  return {
    suggestionObj,
    onChange,
    onKeyDown,
    onClick,
    closeSuggestions,
    searchRef,
    onSearchButtonClick,
    searchBooks,
    onCancelSearchButtonClick,
    enableSearch,
  };
};
export default useSearch;
