//images for biography
import DjuroOne from "../assets/images/biography/djuroOne.png";
import DjuroTwo from "../assets/images/biography/djuroTwo.png";
import DjuroThree from "../assets/images/biography/djuroThree.png";
import DjuroFour from "../assets/images/biography/djuroFour.png";
//other images
import OtherOne from "../assets/images/other/otherOne.png";
import OtherTwo from "../assets/images/other/otherTwo.png";
import OtherThree from "../assets/images/other/otherThree.png";
import OtherFour from "../assets/images/other/otherFour.png";
import OtherFive from "../assets/images/other/otherFive.png";
import OtherSix from "../assets/images/other/otherSix.png";
import OtherSeven from "../assets/images/other/otherEight.png";
import OtherEight from "../assets/images/other/otherNine.png";
import OtherNine from "../assets/images/other/otherTen.png";
import OtherTen from "../assets/images/other/otherEleven.png";
import OtherEleven from "../assets/images/other/otherTwelve.png";

export const galleryArray = [
  OtherOne,
  OtherTwo,
  OtherThree,
  OtherFour,
  OtherFive,
  OtherSix,
  OtherSeven,
  OtherEight,
  OtherNine,
  OtherTen,
  OtherEleven,
  DjuroOne,
  DjuroTwo,
  DjuroThree,
  DjuroFour,
];
