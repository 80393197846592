import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import useWindowSize from "../hooks/useWindowSize";
import { CartItem } from "../../domain/interfaces/book";
import { useRecoilState } from "recoil";
import { cartAtom } from "../../domain/atoms/shopCart";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { currentBooks } from "../../data/books";
import { IoCloseCircleOutline } from "react-icons/io5";
import { Header } from "../containers/Header";
import { MobileHeader } from "../containers/MobileHeader";

export const OrderEBook = () => {
  const { width } = useWindowSize();
  const [cartBooks, setCartBooks] = useState<CartItem[]>([]);
  const [cart, setCart] = useRecoilState(cartAtom);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  });
  const [isEmailEntered, setIsEmailEntered] = useState<boolean>(false);
  const [isPhoneEntered, setIsPhoneEntered] = useState<boolean>(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOnSubmit = async (e: React.FormEvent) => {
    let booksInCart = currentBooks.filter((item) =>
      cartBooks.some(({ id }) => id === item.id)
    );
    e.preventDefault();
    if (
      formData.firstname &&
      formData.lastname &&
      formData.email &&
      formData.phone &&
      isValidEmail(formData.email) &&
      isValidPhoneNumber(formData.phone) &&
      cartBooks.length > 0 &&
      formData.email !== "susnjicpublishing@gmail.com"
    ) {
      try {
        const response = await axios.post("/api/send-mail-ebook", {
          templateName: "user_ebook",
          params: { userData: formData, booksData: booksInCart },
        });
        setIsFormSubmitted(true);
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000);
        setCart(null);
        localStorage.removeItem("books");
        setTimeout(() => navigate("/"), 2000);
      } catch (error) {
        alert(
          "Greska pri naručivanju... Molim Vas proverite unete podatke i pokušajte kasnije."
        );
      }
    } else {
      alert("Molimo Vas popunite sva polja sa validnim podacima.");
    }
  };

  const isValidEmail = (email: string) => {
    // Regular expression for validating an email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phoneNumber: string) => {
    // Regular expression for validating a phone number
    const phoneRegex = /^(?:\+381|0)\d{8,9}$/;
    return phoneRegex.test(phoneNumber);
  };

  // Function to remove an item from the cart
  function removeFromCart(item: CartItem) {
    // Display popup
    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 2000);

    let cart = cartBooks.findIndex((el) => el.id === item.id);
    cartBooks.splice(cart, 1);
    // Update the cart items in local storage
    localStorage.setItem("books", JSON.stringify(cartBooks));
    // Update cart atom
    setCart(cartBooks);
  }

  useEffect(() => {
    let booksInCart = localStorage.getItem("books");

    booksInCart && setCartBooks(JSON.parse(booksInCart));
  }, [cart]);

  const currentCartItems = cartBooks.map((book, index) => {
    let findBookByID = currentBooks.find((el) => el.id === book.id);
    return (
      <div className="orderLiveItem" key={index}>
        <p style={{ marginLeft: "10px" }}>{findBookByID?.name}</p>
        <IoCloseCircleOutline
          style={{ cursor: "pointer" }}
          size={24}
          onClick={() => removeFromCart({ id: Number(findBookByID?.id) })}
        />
      </div>
    );
  });

  return (
    <>
      <Helmet>
        <title>Poručite u elektronskom formatu</title>
        <meta
          name="description"
          content="Otkrijte svet misli i dela Đure Šušnjića. Nudimo ekskluzivni izbor njegovih najvažnijih dela koja obuhvataju širok spektar socioloških i filozofskih tema. Idealno mesto za sve one željne dubokog razumevanja društvenih fenomena."
        />
        <link rel="canonical" href="https://www.djurosusnjic.com/order-ebook" />
      </Helmet>
      {width > 1000 ? <Header /> : <MobileHeader />}
      {/* popup */}
      <div className={`popup ${showPopup ? "show" : ""}`}>
        <div className="popup-content">
          {isFormSubmitted
            ? "Uspešno ste poručili!"
            : "Uspešno ste uklonili knjigu iz korpe!"}
        </div>
      </div>
      {/* popup */}
      <div className="windowElement">
        <div className="topPartWindow">Pregled porudžbine</div>
        {currentCartItems}
        <div
          style={{
            width: "100%",
            borderTop: "1px solid #0D4874",
            margin: "auto",
            color: "#19242f",
            fontSize: "18px",
            height: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {`Ukupno: ${cartBooks.length * 600}RSD`}
        </div>
      </div>
      <div className="orderLiveContent">
        <p style={{ fontSize: "28px", color: "#19242f" }}>Adresa dostave</p>
        <form
          id="orderLiveFormID"
          className="orderLiveForm"
          onSubmit={handleOnSubmit}
        >
          <input
            placeholder="Ime"
            className="orderLiveFormInput"
            required
            name="firstname"
            value={formData.firstname}
            onChange={handleOnChange}
          />
          <input
            placeholder="Prezime"
            className="orderLiveFormInput"
            required
            name="lastname"
            value={formData.lastname}
            onChange={handleOnChange}
          />
          <input
            placeholder="Email"
            className="orderLiveFormInput"
            required
            name="email"
            value={formData.email}
            onChange={handleOnChange}
            onBlur={() => setIsEmailEntered(true)}
          />
          {!isValidEmail(formData.email) && isEmailEntered && (
            <p style={{ color: "#740316", marginTop: 0, marginBottom: "25px" }}>
              Uneta email adresa je nevalidna.
            </p>
          )}
          <input
            placeholder="Telefon"
            className="orderLiveFormInput"
            required
            name="phone"
            value={formData.phone}
            onChange={handleOnChange}
            onBlur={() => setIsPhoneEntered(true)}
          />
          {!isValidPhoneNumber(formData.phone) && isPhoneEntered && (
            <p style={{ color: "#740316", marginTop: 0, marginBottom: "25px" }}>
              Unet broj je nevalidan.
            </p>
          )}
          <button
            type="submit"
            className="addInBasketButton"
            disabled={cartBooks.length === 0}
          >
            Poruči
          </button>
          <p style={{ width: "270px", color: "gray" }}>
            Nakon što poručite e-book, stićiće Vam mejl sa brojem računa i
            ukupnom cenom za uplatu. Nakon uspešne uplate, dobićete pristup
            e-booku putem mejla u roku od 1-2 dana.
          </p>
        </form>
      </div>
    </>
  );
};
