//react
import { useEffect, MutableRefObject } from "react";

export function useOnClickOutside<
  T extends HTMLElement = HTMLElement,
  K extends null = null
>(ref: MutableRefObject<T | K>, handler: (event: MouseEvent) => void) {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
}
