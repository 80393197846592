//router
import { RouterProvider } from "react-router-dom";
import { routerConfig } from "./router/router";
//containers
import { Footer } from "./ui/containers/Footer";
//paypal-provider
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const clientId = process.env.REACT_APP_CLIENT_ID || "YOUR_CLIENT_ID";

function App() {
  return (
    <PayPalScriptProvider options={{ clientId: clientId, currency: 'EUR' }}>
      <div>
        <RouterProvider router={routerConfig} />
        <Footer />
      </div>
    </PayPalScriptProvider>
  );
}

export default App;
