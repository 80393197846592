// store
import { atom } from "recoil";
//interfaces
import { CartItem } from "../interfaces/book";

const cartAtom = atom<CartItem[] | null>({
  key: "cartState",
  default: null,
});

export { cartAtom };
