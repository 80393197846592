import { Helmet } from "react-helmet";
//data
import { opinions } from "../../data/opinions";
import { Header } from "../containers/Header";
import { MobileHeader } from "../containers/MobileHeader";
import useWindowSize from "../hooks/useWindowSize";

export const OpinionsPage = () => {
  const { width } = useWindowSize();

  const opinionItem = opinions.map((item, index) => (
    <div
      key={index}
      style={{
        width: "auto",
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
        height: "120px",
        marginTop: 40,
        backgroundColor: "white",
        borderRadius: "5%",
      }}
    >
      <img
        src={item.image}
        alt="slika"
        style={{
          maxHeight: "120px",
          maxWidth: "120px",
          minHeight: "120px",
          minWidth: "120px",
          borderTopLeftRadius: "5%",
          borderBottomLeftRadius: "5%",
        }}
      />
      <div
        style={{ display: "flex", flexDirection: "column", marginLeft: "20px" }}
      >
        <p style={{ color: "#0d559d" }}>{item.name}</p>
        <a
          href={item.link}
          style={{
            marginTop: -5,
            marginBottom: 5,
            cursor: "pointer",
            fontSize: width < 400 ? "10px" : "14px",
          }}
        >
          Biografija(klikni ovde)
        </a>
        <a
          href={item.opinion}
          style={{ fontSize: width < 400 ? "10px" : "14px" }}
        >
          {item.page.length > 1
            ? `Tekst autora o Đuri Šušnjiću možete pročitati na strani ${item.page}(klikni ovde)`
            : "Tekst autora o Đuri Šušnjiću možete pročitati na ovom linku(klkni ovde)"}
        </a>
      </div>
    </div>
  ));

  return (
    <>
      <Helmet>
        <title>Drugi o nama</title>
        <meta
          name="description"
          content="Otkrijte svet misli i dela Đure Šušnjića. Nudimo ekskluzivni izbor njegovih najvažnijih dela koja obuhvataju širok spektar socioloških i filozofskih tema. Idealno mesto za sve one željne dubokog razumevanja društvenih fenomena."
        />
        <link rel="canonical" href="https://www.djurosusnjic.com/opinions" />
      </Helmet>
      {width > 1000 ? <Header /> : <MobileHeader />}
      <div
        style={{
          width: "100%",
          padding: "15px 0px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "85%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {opinionItem}
        </div>
      </div>
    </>
  );
};
