//router
import { createBrowserRouter } from "react-router-dom";
//pages
import { Error404Page } from "../ui/pages/Error";
import { WelcomePage } from "../ui/pages/WelcomePage";
import { SelectedBookPage } from "../ui/pages/SelectedBook";
import { BiographyPage } from "../ui/pages/Biography";
import { OpinionsPage } from "../ui/pages/Opinions";
import { CartReview } from "../ui/pages/CartReview";
import { OrderLive } from "../ui/pages/OrderLive";
import { GalleryPage } from "../ui/pages/GalleryPage";
import { OrderEBook } from "../ui/pages/OrderLiveEBook";
import SuccessPage from "../ui/pages/SuccessPagePaypal";
import ErrorPage from "../ui/pages/ErrorPagePaypal";

export const routerConfig = createBrowserRouter([
  {
    path: "/",
    element: <WelcomePage />,
    errorElement: <Error404Page />,
  },
  {
    path: "/:id",
    element: <SelectedBookPage />,
    errorElement: <Error404Page />,
  },
  {
    path: "/biography",
    element: <BiographyPage />,
    errorElement: <Error404Page />,
  },
  {
    path: "/opinions",
    element: <OpinionsPage />,
    errorElement: <Error404Page />,
  },
  {
    path: "/gallery",
    element: <GalleryPage />,
    errorElement: <Error404Page />,
  },
  {
    path: "/cart-review",
    element: <CartReview />,
    errorElement: <Error404Page />,
  },
  {
    path: "/order-live",
    element: <OrderLive />,
    errorElement: <Error404Page />,
  },
  {
    path: "/order-ebook",
    element: <OrderEBook />,
    errorElement: <Error404Page />,
  },
  {
    path: "/success-paypal",
    element: <SuccessPage />,
    errorElement: <Error404Page />,
  },
  {
    path: "/error-paypal",
    element: <ErrorPage />,
    errorElement: <Error404Page />,
  },
]);
