//react
import { useEffect, useRef, useState } from "react";
//hooks
import useWindowSize from "./useWindowSize";
//data
import { comingSoonBooks, currentBooks } from "../../data/books";

const useWelcomePage = () => {
  const { width } = useWindowSize();
  const [visibleCurrentBooks, setVisibleCurrentBooks] = useState<
    {
      id: number;
      name: string;
      price: string;
      image: any;
    }[]
  >([]);
  const [visibleComingBooks, setVisibleComingBooks] = useState<
    {
      id: number;
      name: string;
      price: string;
      image: any;
    }[]
  >([]);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [comingSlide, setComingSlide] = useState<number>(0);
  const currentRowRef = useRef(null);
  const comingRowRef = useRef(null);

  const prevBooks = (books: "current" | "coming") => {
    if (books === "current" && currentSlide > 0) {
      setCurrentSlide((prevIndex) =>
        prevIndex === 0 ? currentBooks.length - 1 : prevIndex - 1
      );
    }
    if (books === "coming" && comingSlide > 0) {
      setComingSlide((prevIndex) =>
        prevIndex === 0 ? comingSoonBooks.length - 1 : prevIndex - 1
      );
    }
  };

  const nextBooks = (books: "current" | "coming") => {
    if (
      books === "current" &&
      currentSlide < currentBooks.length - visibleCurrentBooks.length
    ) {
      setCurrentSlide((prevIndex) =>
        prevIndex === currentBooks.length - 1 ? 0 : prevIndex + 1
      );
    }
    if (
      books === "coming" &&
      comingSlide < comingSoonBooks.length - visibleComingBooks.length
    ) {
      setComingSlide((prevIndex) =>
        prevIndex === comingSoonBooks.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  useEffect(() => {
    let lastElement = Math.floor(width / 280);
    setVisibleCurrentBooks(currentBooks.slice(0, lastElement));
    setVisibleComingBooks(comingSoonBooks.slice(0, lastElement));
  }, [width]);

  return {
    visibleCurrentBooks,
    visibleComingBooks,
    prevBooks,
    nextBooks,
    currentSlide,
    comingSlide,
    currentRowRef,
    comingRowRef,
  };
};
export default useWelcomePage;
