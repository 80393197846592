import { Helmet } from "react-helmet";
//hooks
import { useNavigate } from "react-router-dom";
//styles
import "../styles/pages/cartReview.css";
import { MobileHeader } from "../containers/MobileHeader";
import { Header } from "../containers/Header";
import useWindowSize from "../hooks/useWindowSize";
import { useEffect, useState } from "react";
import { CartItem } from "../../domain/interfaces/book";
import { useRecoilState } from "recoil";
import { cartAtom } from "../../domain/atoms/shopCart";
import { currentBooks } from "../../data/books";
import axios from "axios";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { FUNDING } from "@paypal/react-paypal-js";

export const CartReview = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const [cartBooks, setCartBooks] = useState<CartItem[]>([]);
  const [cart, setCart] = useRecoilState(cartAtom);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  // Function to remove an item from the cart
  function removeFromCart(item: CartItem) {
    // Display popup
    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 2000);

    let cart = cartBooks.findIndex((el) => el.id === item.id);
    cartBooks.splice(cart, 1);
    // Update the cart items in local storage
    localStorage.setItem("books", JSON.stringify(cartBooks));
    // Update cart atom
    setCart(cartBooks);
  }

  useEffect(() => {
    let booksInCart = localStorage.getItem("books");

    booksInCart && setCartBooks(JSON.parse(booksInCart));
  }, [cart]);

  const currentCartItems = cartBooks.map((book, index) => {
    let findBookByID = currentBooks.find((el) => el.id === book.id);
    return (
      <div className="cartBookItem" key={index}>
        <img
          src={`${findBookByID?.image}`}
          alt="slika izabrane knjige"
          className="cartBookImage"
        />
        <div className="cartBookBasicContent">
          <div className="leftTopSelectedBook">
            <p className="selectedBookAuthor">Đuro Šušnjić</p>
            <p className="selectedBookName">{findBookByID?.name}</p>
          </div>
          <div className="leftBottomSelectedBook">
            <p className="selectedBookPriceTitle">Cena fizičke kopije</p>
            <p className="selectedBookPrice">RSD{findBookByID?.price}</p>
            <div>
              <p className="selectedBookPriceTitle">Cena e-book</p>
              <p className="selectedBookPrice">RSD{findBookByID?.ebookPrice}</p>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <button
                className="addInBasketButton"
                onClick={() => removeFromCart({ id: Number(findBookByID?.id) })}
              >
                Brisanje
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  });

  function createOrder() {
    const items = createItems(cartBooks);
    return fetch("/api/orders", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        value: cartBooks.length * 5,
        items: items,
      }),
    })
      .then((response) => response.json())
      .then((order) => {
        return order.id;
      });
  }

  function onApprove(data: any) {
    return fetch("/api/orders/capture", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderID: data.orderID,
      }),
    })
      .then((response) => response.json())
      .then((orderData) => {
        const userData = {
          firstname: orderData.payer.name.given_name,
          lastname: orderData.payer.name.surname,
          email: orderData.payer.email_address,
        };
        const booksInCart = currentBooks.filter((item) =>
          cartBooks.some(({ id }) => id === item.id)
        );
        try {
          const response = axios.post("/api/send-internal-mail", {
            templateName: "user_paypal",
            params: { userData: userData, booksData: booksInCart },
          });
        } catch (error) {
          alert("Greska pri naručivanju... Molim Vas pokušajte kasnije.");
        }
        navigate("/success-paypal");
        setCart(null);
        localStorage.removeItem("books");
      })
      .catch((error) => {
        navigate("/error-paypal");
      });
  }

  function createItems(books: any[]): any[] {
    const bookQuantities: { [key: number]: number } = {};
    for (const book of books) {
      if (book.id in bookQuantities) {
        bookQuantities[book.id]++;
      } else {
        bookQuantities[book.id] = 1;
      }
    }

    const bookListWithQuantity: any[] = [];

    for (const book in bookQuantities) {
      const quantity = bookQuantities[book];
      const bookInCart: any = currentBooks.filter(
        (item) => Number(book) === item.id
      )[0];
      const bookObject: any = {
        name: `Đuro Šušnjić - ${bookInCart.name}`,
        quantity: quantity,
        unit_amount: { currency_code: "EUR", value: 5 },
      };
      bookListWithQuantity.push(bookObject);
    }

    return bookListWithQuantity;
  }

  return (
    <div
      style={{
        marginBottom: width < 1025 && cartBooks.length === 0 ? "40vh" : "25vh",
      }}
    >
      <Helmet>
        <title>Pregled porudžbine</title>
        <meta
          name="description"
          content="Otkrijte svet misli i dela Đure Šušnjića. Nudimo ekskluzivni izbor njegovih najvažnijih dela koja obuhvataju širok spektar socioloških i filozofskih tema. Idealno mesto za sve one željne dubokog razumevanja društvenih fenomena."
        />
        <link rel="canonical" href="https://www.djurosusnjic.com/cart-review" />
      </Helmet>
      {width > 1000 ? <Header /> : <MobileHeader />}
      {/* popup */}
      <div className={`popup ${showPopup ? "show" : ""}`}>
        <div className="popup-content">
          Uspešno ste uklonili knjigu iz korpe!
        </div>
      </div>
      {/* popup */}
      {currentCartItems.length > 0 && (
        <div className="cartReviewContent">{currentCartItems}</div>
      )}
      {currentCartItems.length > 0 && (
        <div className="cartActionDiv">
          <div className="onAdressOrEBookDiv">
            <p>Poruči na adresu</p>
            <button
              className="addInBasketButton"
              onClick={() => navigate("/order-live")}
            >
              Poruči
            </button>
          </div>
          <div className="onAdressOrEBookDiv">
            <p>Poruči knjigu/knjige u elektronskom formatu</p>
            <button
              className="addInBasketButton"
              onClick={() => navigate("/order-ebook")}
            >
              Poruči e-book
            </button>
          </div>
          <div className="onAdressOrEBookDiv">
            <p>Kupi knjigu/knjige u elektronskom formatu</p>
            <PayPalButtons
              disabled={false}
              fundingSource={FUNDING.PAYPAL}
              createOrder={createOrder}
              onApprove={onApprove}
            />
          </div>
        </div>
      )}
      {currentCartItems.length < 1 && (
        <div className="emptyBasketDiv">
          <p>Korpa je prazna</p>
          <button className="addInBasketButton" onClick={() => navigate("/")}>
            Početna
          </button>
        </div>
      )}
    </div>
  );
};
