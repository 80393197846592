import { Header } from "../containers/Header";
import { MobileHeader } from "../containers/MobileHeader";
import useWindowSize from "../hooks/useWindowSize";

export const Error404Page = () => {
  const { width } = useWindowSize();

  return (
    <>
      {width > 1000 ? <Header /> : <MobileHeader />}
      <div>
        <p>
          Došlo je do neočekivane greške. Naš tim radi na rešavanju problema.
        </p>
        <p>Molimo Vas da osvežite stranicu ili pokušate ponovo kasnije.</p>
      </div>
    </>
  );
};
