//react
import { Helmet } from "react-helmet";
import { useEffect } from "react";
//styles
import "../styles/pages/welcomePage.css";
//components
import { Search } from "../components/Search";
//hooks
import useWelcomePage from "../hooks/useWelcomePage";
import useSearch from "../hooks/useSearch";
import { useNavigate } from "react-router";
//icons
import { FaCircleArrowLeft } from "react-icons/fa6";
import { FaCircleArrowRight } from "react-icons/fa6";
//data
import {
  allBooksTitles,
  comingSoonBooks,
  currentBooks,
} from "../../data/books";
import { Header } from "../containers/Header";
import { MobileHeader } from "../containers/MobileHeader";
import useWindowSize from "../hooks/useWindowSize";

export const WelcomePage = () => {
  const {
    visibleCurrentBooks,
    visibleComingBooks,
    prevBooks,
    nextBooks,
    currentSlide,
    comingSlide,
    currentRowRef,
    comingRowRef,
  } = useWelcomePage();

  const {
    suggestionObj,
    onChange,
    onKeyDown,
    onClick,
    closeSuggestions,
    searchRef,
    onSearchButtonClick,
    searchBooks,
    onCancelSearchButtonClick,
    enableSearch,
  } = useSearch(allBooksTitles);

  const navigate = useNavigate();
  const { width } = useWindowSize();

  const currentBookItems = currentBooks.map((book, index) => (
    <div
      key={index}
      className="welcomePageBook"
      onClick={() => navigate(`/${book.id}`)}
    >
      <img src={book.image} alt="Trenutna knjiga" className="bookImage" />
      <div className="bookTitle">{book.name}</div>
      <div className="bookPrice">{book.price} RSD</div>
    </div>
  ));
  const comingBookItems = comingSoonBooks.map((book, index) => (
    <div key={index} className="welcomePageBook">
      <img src={book.image} alt="Uskoro knjiga" className="bookImage" />
      <div className="bookTitle">{book.name}</div>
    </div>
  ));
  const searchResultBookItems = searchBooks.map((book, index) => (
    <div
      key={index}
      className="searchWelcomePageBook"
      onClick={book.id <= 7 ? () => navigate(`/${book.id}`) : () => {}}
    >
      <img
        src={book.image}
        alt="Rezultat pretrage knjiga"
        className="bookImage"
      />
      <div className="bookTitle">{book.name}</div>
      {book.price.length > 0 && book.id <= 7 && (
        <div className="bookPrice">{book.price} RSD</div>
      )}
    </div>
  ));

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showBooksRow");
          observer.unobserve(entry.target);
        }
      });
    }, options);

    if (currentRowRef.current) {
      observer.observe(currentRowRef.current);
    }

    if (comingRowRef.current) {
      observer.observe(comingRowRef.current);
    }

    return () => {
      if (currentRowRef.current) {
        /* eslint-disable */
        observer.unobserve(currentRowRef.current);
      }
      if (comingRowRef.current) {
        /* eslint-disable */
        observer.unobserve(comingRowRef.current);
      }
    };
    /* eslint-disable */
  }, [currentRowRef, comingRowRef]);

  return (
    <>
      <Helmet>
        <title>Šušnjic publishing</title>
        <meta
          name="description"
          content="Otkrijte svet misli i dela Đure Šušnjića. Nudimo ekskluzivni izbor njegovih najvažnijih dela koja obuhvataju širok spektar socioloških i filozofskih tema. Idealno mesto za sve one željne dubokog razumevanja društvenih fenomena."
        />
        <link rel="canonical" href="https://www.djurosusnjic.com/" />
      </Helmet>
      {width > 1000 ? <Header /> : <MobileHeader />}
      <div className="welcomePage">
        <div className="belowHeaderBackground" />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Search
            suggestionObj={suggestionObj}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onClick={onClick}
            closeSuggestions={closeSuggestions}
            searchRef={searchRef}
            onSearchButtonClick={onSearchButtonClick}
            onCancelSearchButtonClick={onCancelSearchButtonClick}
            enableSearch={enableSearch}
          />
        </div>
        <div className="welcomePageContent">
          {searchBooks.length > 0 && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <p className="welcomePageTitle">pretraga</p>
              </div>
              <div className="searchWelcomePageRow" id="searchBooksRowId">
                {searchResultBookItems}
              </div>
            </>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <p className="welcomePageTitle">u ponudi</p>
          </div>
          <div
            className="welcomePageBooksRow"
            id="booksRowId"
            ref={currentRowRef}
          >
            {visibleCurrentBooks.length < currentBookItems.length && (
              <FaCircleArrowLeft
                className="arrowIcon"
                onClick={() => prevBooks("current")}
              />
            )}
            {currentBookItems.slice(
              currentSlide,
              currentSlide + visibleCurrentBooks.length
            )}
            {visibleCurrentBooks.length < currentBookItems.length && (
              <FaCircleArrowRight
                className="arrowIcon"
                onClick={() => nextBooks("current")}
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <p className="welcomePageTitle">uskoro</p>
          </div>
          <div
            className="welcomePageBooksRow"
            id="cominBooksRowId"
            ref={comingRowRef}
          >
            {visibleComingBooks.length < comingBookItems.length && (
              <FaCircleArrowLeft
                className="arrowIcon"
                onClick={() => prevBooks("coming")}
              />
            )}
            {comingBookItems.slice(
              comingSlide,
              comingSlide + visibleComingBooks.length
            )}
            {visibleComingBooks.length < comingBookItems.length && (
              <FaCircleArrowRight
                className="arrowIcon"
                onClick={() => nextBooks("coming")}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
