import DraganVukcevic from "../assets/images/opinionsPeople/DraganVukcevic.png";
import RatkoBozovic from "../assets/images/opinionsPeople/RatkoBozovic.png";
import CedomirCupic from "../assets/images/opinionsPeople/CedomirCupic.png";
import DragoljubDjordjevic from "../assets/images/opinionsPeople/DragoljubDjordjevic.png";
import MilanVukomanovic from "../assets/images/opinionsPeople/MilanVukomanovic.png";
import SasaPopovic from "../assets/images/opinionsPeople/SasaPopovic.png";
import DrazenPavlica from "../assets/images/opinionsPeople/DrazenPavlica.png";

export const opinions = [
  {
    id: 1,
    name: "Dragan K. Vukčević",
    link: "https://hs.udg.edu.me/predavaci/biografija/1-dragan-k.-vuk%C4%8Devi%C4%87",
    opinion:
      "https://drive.google.com/file/d/11e8MT7fStjd0zn6Xuc0CtlZrkKOjpFhM/view?usp=drive_link",
    image: DraganVukcevic,
    page: "93",
  },
  {
    id: 2,
    name: "Ratko Božović",
    link: "https://www.wikiwand.com/sr/Ratko_Bo%C5%BEovi%C4%87",
    opinion:
      "https://drive.google.com/file/d/11e8MT7fStjd0zn6Xuc0CtlZrkKOjpFhM/view?usp=drive_link",
    image: RatkoBozovic,
    page: "39",
  },
  {
    id: 3,
    name: "Čedomir Čupić",
    link: "https://hs.udg.edu.me/predavaci/biografija/96-%C4%8Dedomir-%C4%8Dupi%C4%87",
    opinion:
      "https://drive.google.com/file/d/11e8MT7fStjd0zn6Xuc0CtlZrkKOjpFhM/view?usp=drive_link",
    image: CedomirCupic,
    page: "17",
  },
  {
    id: 4,
    name: "Dragoljub Đorđević",
    link: "https://www.masfak.ni.ac.rs/index.php/sr/2015-03-03-09-41-37/14-2015-02-24-13-46-59/297-d-rd-vic-b-dr-g-ljub",
    opinion:
      "https://drive.google.com/file/d/11e8MT7fStjd0zn6Xuc0CtlZrkKOjpFhM/view?usp=drive_link",
    image: DragoljubDjordjevic,
    page: "121",
  },
  {
    id: 5,
    name: "Milan Vukomanović",
    link: "https://isi.f.bg.ac.rs/milan-vukomanovic/",
    opinion:
      "https://drive.google.com/file/d/11e8MT7fStjd0zn6Xuc0CtlZrkKOjpFhM/view?usp=drive_link",
    image: MilanVukomanovic,
    page: "149",
  },
  {
    id: 6,
    name: "Saša Popović",
    link: "https://www.ucg.ac.me/radnik/110248",
    opinion:
      "https://docs.google.com/document/d/1iFbp6gBt6Kyp9RESr0gUSf1J_M5V9h3I/edit?usp=drive_link&ouid=104158861558924640430&rtpof=true&sd=true",
    image: SasaPopovic,
    page: "",
  },
  {
    id: 7,
    name: "Dražen Pavlica",
    link: "https://www.centarzaistrazivanja.com/nas-tim/drazen-pavlica/",
    opinion:
      "https://drive.google.com/file/d/11e8MT7fStjd0zn6Xuc0CtlZrkKOjpFhM/view?usp=drive_link",
    image: DrazenPavlica,
    page: "197",
  },
];
